<template>
  <div class="step3 box">
    <div class="title">
      <i class="el-icon-caret-right icon"></i>
      <span class="font26 color33">上传资料</span>
      <i class="el-icon-caret-left icon"></i>
    </div>

    <div class="file_box">
      <div class="item" @click="downLoad">
        <p class="font16 color33">
          <i>*</i>下载表格：
        </p>
        <div class="upload_box">
          <img src="../../assets/down_icon.png" alt />
          <p class="font14 color99">点击下载</p>
        </div>
      </div>
      <div class="item">
        <p class="font16 color33">
          <i>*</i>上传文档：
        </p>
        <div class="upload_box">
          <input
            type="file"
            accept="image/*"
            class="upload_table"
            @change="uploadTable($event)"
            alt
            title
          />

          <img v-if="filePath" :src="filePath" alt class="my_file" />
          <img v-else src="../../assets/file_icon.png" alt />
          <p class="font14 color99" v-if="!filePath">点击上传图片jpg、jpeg 格式<br />(请上传2MB及以下大小的图片)</p>
        </div>
      </div>
    </div>
    <!-- {{ myForm.planProfileList }} -->
    <div v-for="(item, index) in profileList" :key="index">
      <div class="file_box" v-if="item.title.indexOf('身份证') != -1">
        <div class="item">
          <p class="font16 color33">
            <span class="el-icon-question" style="color: #999999" @click="changeProFileItem(item)"></span>
            <i v-if="item.isRequire == 1">*</i>
            {{ item.title }}：
          </p>
          <div class="upload_box">
            <i class="el-icon-close upload_box_delete" v-if="item.isRequire == 1 ? false : item.fileImgData != '' && item.fileImgData[0] != ''" @click="handeldelete(item,index)" />
            <input
              type="file"
              accept="image/*"
              class="upload_table"
              @change="uploadfile($event, item, 1, 0)"
              alt
              title
            />

            <img
              v-if="item.pathList.length != 0 && item.pathList[0]"
              :src="item.pathList[0]"
              alt
              class="my_file"
            />
            <img v-else src="../../assets/before_icon.png" alt />
            <p
              class="font14 color99"
              v-if="item.pathList.length == 0 && !item.pathList[0]"
            >点击上传图片jpg、jpeg 格式<br />(请上传2MB及以下大小的图片)</p>
          </div>
        </div>
        <div class="item">
          <div class="upload_box">
            <i class="el-icon-close upload_box_delete" v-if="item.isRequire == 1 ? false : item.fileImgData != '' && item.fileImgData[0] != ''" @click="handeldelete(item,index)" />
            <input
              type="file"
              accept="image/*"
              class="upload_table"
              @change="uploadfile($event, item, 1, 1)"
              alt
              title
            />

            <img
              v-if="item.pathList.length != 0 && item.pathList[1]"
              :src="item.pathList[1]"
              alt
              class="my_file"
            />
            <img v-else src="../../assets/back_icon.png" alt />
            <p class="font14 color99" v-if="!item.pathList[1]">点击上传图片jpg、jpeg 格式<br />(请上传2MB及以下大小的图片)</p>
          </div>
        </div>
      </div>
      <div class="file_box" v-else>
        <div class="item">
          <p class="font16 color33">
            <span class="el-icon-question" style="color: #999999" @click="changeProFileItem(item)"></span>
            <i v-if="item.isRequire == 1">*</i>
            {{ item.title }}
          </p>
          <div class="upload_box">
            <i class="el-icon-close upload_box_delete" v-if="item.isRequire == 1 ? false : item.fileImgData != '' && item.fileImgData[0] != ''" @click="handeldelete(item,index)" />
            <input
              type="file"
              ref="inputRef"
              accept="image/*"
              class="upload_table"
              @change="uploadfile($event, item)"
              alt
              title
            />
            <img
              v-if="item.fileImgData && item.fileImgData[0] != ''"
              :src="item.fileImgData"
              alt
              class="my_file"
            />

            <img v-else src="../../assets/other_file_icon.png" alt />
            <p v-if="!item.fileImgData" class="font14 color99">点击上传图片jpg、jpeg 格式<br />(请上传2MB及以下大小的图片)</p>
          </div>
        </div>
      </div>
    </div>

    <div class="btn_list">
      <el-button plain class="last_btn" @click="lastStep">上一步</el-button>
      <el-button type="primary" class="next_btn" @click="toCommit">提交</el-button>
    </div>

    <el-dialog title="查看材料要求" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
      <div class="content">
        <span>{{ changeItem.requireNotes }}</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { compressAccurately } from "image-conversion"; //按需引入

import { downBlobFile } from "@/util/request";
import {
  uploadFileTable,
  getPlanInfo,
  getStudentInfo,
  confirmProfile,
  generateOrder,
  signUp,
  updatePlanStudentStatus
} from "@/util/api";
export default {
  data() {
    return {
      dialogVisible: false,
      filePath: null,
      isTable: false,
      showImage: false,
      profileList: [],
      openFileList: [],
      changeItem: {},
      fullscreenLoading: null
    };
  },
  mounted() {
    this.toGetStudentInfo();
  },
  methods: {
    handlePreview(file) {
      this.showImage = true;
      console.log(file);
      this.openFileList = file;
    },
    toGetStudentInfo() {
      getStudentInfo({
        planId: this.$route.query.planId
      }).then(res => {
        this.myForm = res.data;
        this.filePath = res.data.filePath;
        if (this.myForm.profileList.length != 0) {
          this.profileList = this.myForm.profileList;
          for (let i in this.profileList) {
            this.profileList[i].pathList = this.profileList[i].filePath.split(
              ";"
            );
            this.profileList[i].fileImgData = this.profileList[i].pathList;
          }
          return;
        }

        getPlanInfo({
          planId: this.$route.query.planId,
          domainUrl: window.location.hostname
        }).then(ress => {
          this.profileList = ress.data.planProfileList;
          for (let i in this.profileList) {
            this.profileList[i].id = null;
            this.profileList[i].planId = this.$route.query.planId;
            this.profileList[i].studentId = this.myForm.student.id;
            this.profileList[i].fileImgData = "";
            this.profileList[i].pathList = [];
          }

          this.$forceUpdate();
        });
      });
    },
    // 删除
    handeldelete(data, index = "") {
      this.$confirm("是否删除", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.profileList[index].fileImgData = '';
          // this.profileList[index].pathList = [''];
          this.profileList[index].filePath = '';
          this.profileList[index].pathList = [];
          this.$refs.inputRef[0].value = ''
          this.$forceUpdate();
        })
        .catch(() => {});
    },
    // 下载表格
    downLoad() {
      downBlobFile(
        "/applyexam/downloadFile",
        {
          planId: this.$route.query.planId
        },
        "个人信息报名表格.pdf"
      );
      // console.log(this.$route.query.planId);
    },
    // 上传表格
    uploadTable(e) {
      let that = this;
      let file = e.target.files[0];

      /* 限制文件上传类型 */
      let suffixName = file.name.substring(
        file.name.lastIndexOf(".") + 1
      ); /* 得到文件后缀名 */
      if (suffixName !== "jpg" && !suffixName !== "jpeg") {
        this.$message.warning("上传文件只能是 jpg、jpeg 格式!");
        return;
      }
      let base64Img;
      /* 限制文件上传大小 */
      let isLtSize = file.size / 1024 / 1024 <= 2;
      if (isLtSize) {
        return new Promise(resolve => {
          // 压缩到100KB,这里的100就是要压缩的大小,可自定义
          compressAccurately(e.target.files[0], 2000).then(myImg => {
            const fr = new FileReader();
            fr.readAsDataURL(myImg);
            fr.onload = function(e) {
              base64Img = e.target.result;
              that.filePath = base64Img;
              uploadFileTable({
                fileData: base64Img,
                planId: that.$route.query.planId,
                studentId: that.myForm.student.id
              })
                .then(res => {
                  that.isTable = true;
                })
                .catch(err => {});
            };
            resolve(myImg);
          });
        });
      } else {
        this.$message.warning("请选择2MB及以下的图片")
        return
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function(e) {
          base64Img = e.target.result;
          that.filePath = base64Img;
          uploadFileTable({
            fileData: base64Img,
            planId: that.$route.query.planId,
            studentId: that.myForm.student.id
          })
            .then(res => {
              that.isTable = true;
            })
            .catch(err => {});
        };
      }
    },
    // 上传附件
    uploadfile(e, item, i, index) {
      let that = this;
      let file = e.target.files[0];

      /* 限制文件上传类型 */
      let suffixName = file.name.substring(
        file.name.lastIndexOf(".") + 1
      ); /* 得到文件后缀名 */
      if (suffixName !== "jpg" && !suffixName !== "jpeg") {
        that.$message.warning("上传文件只能是 jpg、jpeg 格式!");
        return;
      }

      /* 限制文件上传大小 */
      let isLtSize = file.size / 1024 / 1024 <= 2;
      if (isLtSize) {
        return new Promise(resolve => {
          // 压缩到100KB,这里的100就是要压缩的大小,可自定义
          compressAccurately(e.target.files[0], 2000).then(myImg => {
            var reader = new FileReader();
            reader.readAsDataURL(myImg);
            reader.onload = function(e) {
              if (i) {
                item.pathList[index] = e.target.result;
                item.fileImgData = item.pathList;
                that.$forceUpdate();
                return;
              }
              item.fileImgData = e.target.result;
              that.$forceUpdate();
            };
            resolve(myImg);
          });
        });
      }else{
        this.$message.warning("请选择2MB及以下的图片");
        return
      }
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function(e) {
        if (i) {
          item.pathList[index] = e.target.result;
          item.fileImgData = item.pathList;
          that.$forceUpdate();
          return;
        }
        item.fileImgData = e.target.result;
        that.$forceUpdate();
      };
    },
    // 提交
    toCommit() {
      let isTrue = true;
      let isCard = true;
      for (let i in this.profileList) {
        if (
          this.profileList[i].title.indexOf("身份证") != -1 &&
          this.profileList[i].fileImgData.length != 2 &&
          this.profileList[i].isRequire == 1
        ) {
          isCard = false;
        }
        if (
          this.profileList[i].isRequire == 1 &&
          !this.profileList[i].fileImgData
        ) {
          isTrue = false;
        }
      }
      if (!isCard) {
        this.$message.warning("身份证带*号为必须上传两张图片");
        return;
      }
      if (!isTrue) {
        this.$message.warning("带*号为必须上传项");
        return;
      }
      if (!this.filePath) {
        this.$message.warning("表格项未上传");
        return;
      }
      this.fullscreenLoading = this.$loading({
        lock: true,
        text: "正在提交",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.6)"
      });
      confirmProfile(this.profileList)
        .then(() => {
          if (this.$route.query.isChange) {
            updatePlanStudentStatus({
              planStudentId: this.$route.query.planStudentId
            }).then(() => {
              this.$message.success("修改成功！");
              this.$router.replace("/user/mySign");
            });
            return;
          }
          this.toSign();
        })
        .catch(err => {
          this.toGetStudentInfo();
          this.fullscreenLoading.close();
        }).finally(()=>{
          this.fullscreenLoading.close();
        });
    },
    // 报名事件
    toSign() {
      console.log(this.$route.query.partnerId, "yyyyyyyyyy");
      if (this.$route.query.partnerId == "null") {
        signUp({
          planId: this.$route.query.planId
        })
          .then(res => {
            if (this.$route.query.isNotFree == 2) {
              generateOrder({
                planId: this.$route.query.planId
              })
                .then(ress => {
                  // this.orderNo = res.orderNo;
                  this.$notify({
                    title: "成功",
                    message: "报名成功",
                    type: "success"
                  });
                  window.localStorage.setItem("orderNo", ress.data.orderNo);
                  this.$emit("changeStep", 4);
                  this.fullscreenLoading.close();
                })
                .catch(() => {
                  this.fullscreenLoading.close();
                  this.toGetStudentInfo();
                });
            } else if (this.$route.query.isNotFree == 1) {
              window.localStorage.setItem("paystatus", true);
              this.$emit("changeStep", 5);
              this.fullscreenLoading.close();
            } else if (this.$route.query.isNotFree == 3) {
              window.localStorage.setItem("paystatus", 5);
              this.$emit("changeStep", 5);
              this.fullscreenLoading.close();
            }
          })
          .catch(error => {
            this.toGetStudentInfo();
            this.fullscreenLoading.close();
            setTimeout(() => {
              this.$rouer.push({
                path: "/signCenter",
                query: {
                  orderNo: orderNo
                }
              });
            });
          });
      } else {
        signUp({
          planId: this.$route.query.planId,
          partnerId: this.$route.query.partnerId
        })
          .then(res => {
            if (this.$route.query.isNotFree == 2) {
              generateOrder({
                planId: this.$route.query.planId
              })
                .then(ress => {
                  // this.orderNo = res.orderNo;
                  this.$notify({
                    title: "成功",
                    message: "上传成功",
                    type: "success"
                  });
                  window.localStorage.setItem("orderNo", ress.data.orderNo);
                  this.$emit("changeStep", 4);
                  this.fullscreenLoading.close();
                })
                .catch(() => {
                  this.fullscreenLoading.close();
                  this.toGetStudentInfo();
                });
            } else if (this.$route.query.isNotFree == 1) {
              window.localStorage.setItem("paystatus", true);
              this.$emit("changeStep", 5);
              this.fullscreenLoading.close();
            } else if (this.$route.query.isNotFree == 3) {
              window.localStorage.setItem("paystatus", 5);
              this.$emit("changeStep", 5);
              this.fullscreenLoading.close();
            }
          })
          .catch(error => {
            this.toGetStudentInfo();
            this.fullscreenLoading.close();
            setTimeout(() => {
              this.$rouer.push({
                path: "/signCenter",
                query: {
                  orderNo: orderNo
                }
              });
            });
          });
      }
    },
    changeProFileItem(item) {
      this.changeItem = item;
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    toStep() {
      if (this.$route.query.isNotFree == 3) {
        this.$emit("changeStep", 4);
      } else {
        this.$emit("changeStep", 3);
      }
    },
    lastStep() {
      if (this.$route.query.isChange) {
        this.$emit("changeStep", 1);
      } else {
        this.$emit("changeStep", 2);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  text-align: center;
  .icon {
    color: #2b68d9;
    margin: 0 10px;
  }
}

.file_box {
  width: 80%;
  margin: 35px auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  .item {
    position: relative;
    cursor: pointer;
    .upload_table {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
  i {
    font-style: normal;
    color: #ff5b5b;
  }
  .upload_box {
    width: 240px;
    height: 120px;
    background: rgba($color: #2b68d9, $alpha: 0.1);
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    margin-top: 20px;
    transition: all 0.5s;
    overflow: hidden;
    img {
      width: 42px;
      margin-bottom: 10px;
    }
    &_delete {
      position: absolute;
      right: 0px;
      top: 0px;
      font-size: 20px;
      cursor: pointer;
      z-index: 10;
    }
    .my_file {
      height: 80%;
      width: auto;
      margin-bottom: 0;
    }
    .mark {
      position: absolute;
      content: "";
      width: 100%;
      height: 0%;
      background-color: rgba($color: #000000, $alpha: 0.5);
      // display: none;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.5s;
      z-index: 10;
      border-radius: 50%;
      overflow: hidden;
      p {
        background: #fff;
        width: 100%;
        text-align: center;
        font-size: 14px;
        color: #2b68d9;
        padding: 8px 0;
      }
    }
  }
}
.upload_box:hover {
  .mark {
    height: 100%;
    border-radius: 0;
  }
}

.btn_list {
  display: flex;
  justify-content: center;
  button {
    width: 130px;
    height: 32px;
    line-height: 32px;
    border-radius: 10px;
    padding: 0;
    line-height: 32px;
    margin: 30px;
  }
  .last_btn {
    background-color: #fff;
    border: 1px solid #2b68d9;
    color: #2b68d9;
  }
  .next_btn {
    background: #2b68d9;
  }
}
.content {
  min-height: 300px;
}
</style>
<style scoped>
.step3 >>> .img_class img {
  /* .img_class {
  img { */
  width: 100%;
  height: 100%;
  opacity: 0;
  /* }
} */
}
.step3 >>> .viewer-container img {
  opacity: 1;
}
</style>
